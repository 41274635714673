<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <template #[`item.distributor_id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'distributor-view', params: { id: item.distributor_id } }"
        >
          {{ item.distributor ? item.distributor.nicename : '-' }}
        </router-link>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <edit-dialog
                v-if="$can('distributor_team:update', 'distributor_team_management')"
                :disabled-fields="['distributor_id']"
                :resource="item"
                @updated="paginate"
              />

              <!-- <v-list-item
                  link

                  v-if="$can('distributor_team:delete', 'distributor_team_management')"
                >
                  <v-list-item-title>
                    <v-icon size="18" class="me-3">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import {
  mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync,
} from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'nicename' },
      { text: 'Users', value: 'users_count' },
      { text: 'Distributor', value: 'distributor_id' },
      { text: 'DATE', value: 'created_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>
