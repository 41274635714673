<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Team
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.nicename"
              label="Name"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('nicename')"
            />
          </v-col>

          <v-col
            v-if="!disabledFields.includes('distributor_id')"
            cols="12"
          >
            <v-autocomplete
              v-model="form.distributor_id"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              label="Distributor"
              outlined
              :rules="[validators.required]"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-switch
              v-model="form.active"
              label="Active"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor } from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      default: null,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { distributorOptions } = useDistributor()
    const isEditing = ref(false)

    const defaultFormValue = {
      nicename: null,
      distributor_id: null,
      active: true,
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const parseResource = resource => ({
      nicename: resource.nicename,
      distributor_id: resource.distributor_id,
      active: resource.active,
    })

    onMounted(() => {
      if (props.resource) {
        isEditing.value = true

        form.value = parseResource(props.resource)
      }
    })

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      emit('close')
    }

    return {
      distributorOptions,
      isEditing,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
